import '@plone/volto/config';

/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */
import {
  CardWithSideImageTemplate,
  CardWithSideImageTemplateSkeleton,
  CollaborationTableTemplate,
  CollaborationTableTemplateSkeleton,
  DirigentiTableTemplate,
  DirigentiTableTemplateSkeleton,
  NoCardTemplate,
  NoCardTemplateSkeleton,
} from '@package/components';
import {
  addCardWithSideImageTemplateOptions,
  addNoCardTemplateOptions,
} from '@package/config/Blocks/ListingOptions';
import {
  addDefaultOptions,
  templatesOptions,
} from '@italia/config/Blocks/ListingOptions';

import AtletaView from '@package/components/Views/AtletaView/AtletaView';
import ConsulenzaView from '@package/components/Views/ConsulenzaView/ConsulenzaView';
import DirigenteView from '@package/components/Views/DirigenteView/DirigenteView';
import RepartoView from '@package/components/Views/RepartoView/RepartoView';
import RicercaRepartiEdit from '@package/components/Blocks/RicercaReparti/Edit';
import RicercaRepartiView from '@package/components/Blocks/RicercaReparti/View';
import StoriaView from '@package/components/Views/StoriaView/StoriaView';
import applyItaliaConfig from '@italia/config/italiaConfig';
import bookmarkSVG from '@package/icons/bookmark-regular.svg';
import boxesSVG from '@package/icons/boxes-solid.svg';
import consulenzaSVG from '@package/icons/consulenza.svg';
import loadable from '@loadable/component';
import { removeListingVariation } from '@italia/config/Blocks/listingVariations.js';
import zoomSVG from '@plone/volto/icons/zoom.svg';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings.matomoSiteId = '6vqEl1apQO';
  config.settings.matomoUrlBase = 'https://ingestion.webanalytics.italia.it/';

  config.settings.isMultilingual = true;
  config.settings.supportedLanguages = ['it', 'en'];
  config.settings.defaultLanguage = 'it';
  config.settings.notSupportedBrowsers = [];

  config.settings.italiaThemeViewsConfig.imagePosition = 'afterHeader'; // possible values: afterHeader, documentBody
  config.settings.querystringAdditionalFields = ['start_from'];
  config.settings.siteProperties = {
    ...config.settings.siteProperties,
    siteTitle: { default: '', en: '' },
    siteSubtitle: { default: '', en: '' },
    // siteSubtitle: {
    //   default:
    //     'In Italia e in Europa, per la legalità economico-finanziaria. Tradizione, competenze e tecnologie innovative al servizio dei cittadini',
    //   en: 'In Italy and in Europe, for economic-financial legality. Tradition, skills and innovative technologies at the service of citizens',
    // },
    parentSiteTitle: {
      default: "Ministero dell'Economia e delle Finanze",
      en: 'Ministry of Economy and Finance',
    },
    parentSiteURL: 'https://www.mef.gov.it/',
    subsiteParentSiteTitle: {
      default: 'Guardia di Finanza',
      en: 'Guardia di Finanza',
    },
    arLoginUrl: '/it/area-riservata',
    arLogoutUrl: '/logout',
    headerslimTertiaryMenu: {
      it: [{ title: 'Contatti', url: '/it/chi-siamo/contatti' }],
      en: [{ title: 'Contacts', url: '/en/contacts' }],
    },
    // smallFooterLinks: {
    //   default: [
    //     { title: 'Mappa del sito', url: '/it/sitemap' },
    //   ],
    // },
    splitMegamenuColumns: false,
  };

  config.settings.contentIcons = {
    ...config.settings.contentIcons,
    Storia: bookmarkSVG,
    Reparto: boxesSVG,
    Consulenza: consulenzaSVG,
  };

  config.settings.customDefaultIndexes = [
    ...(config.settings.customDefaultIndexes ?? []),
    // 'ufficio_responsabile_bando',
    // 'ufficio_responsabile_procedimento',
  ];
  /* lista delle colonne custom da poter visualizzare nella Content Folder */
  config.settings.customIndexes = {
    ...(config.settings.customIndexes ?? {}),
    ufficio_responsabile_bando: {
      label: 'Bandi gara - Uff. Resp.',
      type: 'object',
      sort_on: 'ufficio_responsabile_bando',
    },
    ufficio_responsabile_procedimento: {
      label: 'Consulenze - Uff. Resp.',
      type: 'string',
      sort_on: 'ufficio_responsabile_procedimento',
    },
  };
  config.settings.defaultExcludedFromSearch = {
    portalTypes: ['Image'],
  };

  config.settings.videoAllowExternalsDefault = true;

  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'cardWithSideImageTemplate',
      isDefault: false,
      title: 'Card con immagine affiancata',
      template: CardWithSideImageTemplate,
      skeleton: CardWithSideImageTemplateSkeleton,
      schemaEnhancer: ({ schema, formData, intl }) => {
        let pos = addDefaultOptions(schema, formData, intl);
        addCardWithSideImageTemplateOptions(schema, formData, intl, pos);
        return schema;
      },
    },
    {
      id: 'noCard',
      isDefault: false,
      title: 'Elenco senza card',
      template: NoCardTemplate,
      skeleton: NoCardTemplateSkeleton,
      schemaEnhancer: ({ schema, formData, intl }) => {
        let pos = addNoCardTemplateOptions(schema, formData, intl);
        templatesOptions(
          schema,
          formData,
          intl,
          [
            'show_icon',
            'hide_dates',
            'show_section',
            'show_type',
            'show_description',
          ],
          {
            hide_dates: { default: false },
            show_type: { default: false },
          },
          pos,
        );
        return schema;
      },
    },
    {
      id: 'collaboration_table',
      isDefault: false,
      title: 'Tabella collaborazioni',
      template: CollaborationTableTemplate,
      skeleton: CollaborationTableTemplateSkeleton,
      fullobjects: true,
      // schemaEnhancer: ({ schema, formData, intl }) => {
      //   let pos = addNoCardTemplateOptions(schema, formData, intl);
      //   templatesOptions(
      //     schema,
      //     formData,
      //     intl,
      //     [
      //       'show_icon',
      //       'hide_dates',
      //       'show_section',
      //       'show_type',
      //       'show_description',
      //     ],
      //     {
      //       hide_dates: { default: false },
      //       show_type: { default: false },
      //     },
      //     pos,
      //   );
      //   return schema;
      // },
    },
    {
      id: 'dirigenti_table',
      isDefault: false,
      title: 'Tabella dirigenti',
      template: DirigentiTableTemplate,
      skeleton: DirigentiTableTemplateSkeleton,
      schemaEnhancer: ({ schema, formData, intl }) => {
        addDefaultOptions(schema, formData, intl);
        return schema;
      },
    },
  ];

  config.blocks.blocksConfig.listing.listing_items_colors = [
    { name: 'default', label: 'Default' },
    { name: 'light-blue', label: 'Azzurro' },
    { name: 'light-grey', label: 'Grigio chiaro' },
    { name: 'dark-grey', label: 'Grigio scuro' },
  ];

  config.blocks.blocksConfig.listing.listing_bg_colors = [
    { name: 'light', label: 'Default' },
    { name: 'light-grey', label: 'Grigio chiaro' },
    { name: 'dark-grey', label: 'Grigio scuro' },
  ];

  config.blocks.blocksConfig.ricercaReparti = {
    id: 'ricercaReparti',
    title: 'Ricerca Reparti',
    icon: zoomSVG,
    group: 'search',
    view: RicercaRepartiView,
    edit: RicercaRepartiEdit,
    restricted: false,
    mostUsed: false,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  };

  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    Atleta: AtletaView,
    Storia: StoriaView,
    Reparto: RepartoView,
    Consulenza: ConsulenzaView,
    Dirigente: DirigenteView,
  };
  removeListingVariation(config, 'amministrazioneTrasparenteTablesTemplate');

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.text.it.description =
    "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookies analitici per raccogliere informazioni sull'uso del sito da parte degli utenti.  Utilizza anche cookies di profilazione dell'utente per fini statistici. Per i cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Impostazioni'. Per saperne di più, su come disabilitare i cookies oppure abilitarne solo alcuni, consulta la nostra <a href='/it/privacy-policy'>Cookie Policy</a>.";
  config.settings['volto-gdpr-privacy'].defaultPanelConfig.last_updated =
    '2022-09-29T15:30:00+00:00';
  config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical.choices = [
    {
      config_key: 'MATOMO',
      text: {
        it: {
          title: 'Matomo',
          description:
            "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
        },
        en: {
          title: 'Matomo',
          description:
            'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
        },
        fr: {
          title: 'Matomo',
          description:
            "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
        },
      },
    },
    // ...config.settings[
    //   'volto-gdpr-privacy'
    // ].defaultPanelConfig.technical.choices.filter(
    //   (f) => f.config_key !== 'GANALYTICS',
    // ),
  ];

  // SUBSITES
  config.settings.loadables = {
    ...config.settings.loadables,
    'subsite-banda-del-corpo': loadable.lib(() =>
      import('@package/subsites/banda-del-corpo'),
    ),
    'subsite-baschi-verdi': loadable.lib(() =>
      import('@package/subsites/baschi-verdi'),
    ),
    'subsite-cinofili': loadable.lib(() =>
      import('@package/subsites/cinofili'),
    ),
    'subsite-fiamme-gialle': loadable.lib(() =>
      import('@package/subsites/fiamme-gialle'),
    ),
    'subsite-light-teal': loadable.lib(() =>
      import('@package/subsites/light-teal'),
    ),
    'subsite-servizio-aereo': loadable.lib(() =>
      import('@package/subsites/servizio-aereo'),
    ),
    'subsite-servizio-navale': loadable.lib(() =>
      import('@package/subsites/servizio-navale'),
    ),
    'subsite-soccorso-alpino': loadable.lib(() =>
      import('@package/subsites/soccorso-alpino'),
    ),
  };

  return config;
}
